import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import { PrimaryButton } from '@entur/button';
import { ExpandableText } from '@entur/expand';
import { Heading2, CodeText } from '@entur/typography';
import { Table, TableHead, TableBody, TableRow, HeaderCell, DataCell } from '@entur/table';
import { AddIcon } from '@entur/icons';
import * as icons from '@entur/icons';
import IconList from '~/components/IconList';
import PageHeader from '~/components/PageHeader';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import alignDoSrc from './align-do.png';
import alignDontSrc from './align-dont.png';
import colorDoSrc from './color-do.png';
import colorDontSrc from './color-dont.png';
import sizeDoSrc from './size-do.png';
import sizeDontSrc from './size-dont.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <h2 {...{
      "id": "søk-etter-et-ikon"
    }}>{`Søk etter et ikon`}</h2>
    <IconList icons={icons} mdxType="IconList" />
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <p>{`Enturs UI-ikoner er funksjonelle ikoner, til bruk i alle Enturs digitale kanaler. Alle ikonene er laget på huset – ta kontakt
på `}<a parentName="p" {...{
        "href": "https://entur.slack.com/archives/C899QSPB7"
      }}>{`#talk-designsystem`}</a>{` hvis det mangler et ikon du trenger.`}</p>
    <ExpandableText title="Props" titleElement="Heading3" mdxType="ExpandableText">
  <Table mdxType="Table">
    <TableHead mdxType="TableHead">
      <TableRow mdxType="TableRow">
        <HeaderCell mdxType="HeaderCell">Navn</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Type</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Default-verdi</HeaderCell>
        <HeaderCell mdxType="HeaderCell">Beskrivelse</HeaderCell>
      </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">inline?</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">boolean</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">false</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell">Om ikonet skal brukes inline i tekst</DataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">size?</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">string | number</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell"></DataCell>
        <DataCell mdxType="DataCell">Størrelse til ikonet. Trumfer `width` og `height`</DataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">color?</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">string</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell"></DataCell>
        <DataCell mdxType="DataCell">Overskriver fargen til ikonet</DataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">height?</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">string | number</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell"></DataCell>
        <DataCell mdxType="DataCell">
          Høyden til ikonet. Streng tar CSS-verdier, tall blir kalkulert som
          pixler
        </DataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">width?</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell">
          <CodeText mdxType="CodeText">string | number</CodeText>
        </DataCell>
        <DataCell mdxType="DataCell"></DataCell>
        <DataCell mdxType="DataCell">
          Bredden til ikonet. Streng tar CSS-verdier, tall blir kalkulert som
          pixler
        </DataCell>
      </TableRow>
    </TableBody>
  </Table>
    </ExpandableText>
    <h4 {...{
      "id": "inline"
    }}>{`inline`}</h4>
    <p>{`Alle ikoner har støtte for propen `}<inlineCode parentName="p">{`inline`}</inlineCode>{`, som forenkler bruken av ikoner når de skal stå i en tekstlig sammenheng.
Dette brukes først og fremst internt i komponenter som bruker komponenter, men anbefales å bruke sammen med knapper og andre komponenter hvor man har behov for et inline ikon.`}</p>
    <Playground __position={3} __code={'<div>\n  Se dette ikoner ligger inline med teksten{\' \'}\n  <AddIcon inline aria-label=\"(ikoneksempel som ligger på linje)\" />\n  <br /> i motsetning til dette som ligger litt for høyt opp{\' \'}\n  <AddIcon aria-label=\"(ikoneksempel som ligger litt for høyt)\" />\n  <br />\n  <br />\n  <PrimaryButton>\n    Legg til <AddIcon aria-hidden=\"true\" />\n  </PrimaryButton>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      PrimaryButton,
      ExpandableText,
      Heading2,
      CodeText,
      Table,
      TableHead,
      TableBody,
      TableRow,
      HeaderCell,
      DataCell,
      AddIcon,
      icons,
      IconList,
      PageHeader,
      DoDontGroup,
      DoDontCard,
      alignDoSrc,
      alignDontSrc,
      colorDoSrc,
      colorDontSrc,
      sizeDoSrc,
      sizeDontSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    Se dette ikoner ligger inline med teksten{' '}
    <AddIcon inline aria-label="(ikoneksempel som ligger på linje)" mdxType="AddIcon" />
    <br /> i motsetning til dette som ligger litt for høyt opp <AddIcon aria-label="(ikoneksempel som ligger litt for høyt)" mdxType="AddIcon" />
    <br />
    <br />
    <PrimaryButton mdxType="PrimaryButton">
      Legg til <AddIcon aria-hidden="true" mdxType="AddIcon" />
    </PrimaryButton>
  </div>
    </Playground>
    <h3 {...{
      "id": "react-native"
    }}>{`React Native`}</h3>
    <p>{`Ikoner er også støttet for React Native, ved å importere fra `}<inlineCode parentName="p">{`@entur/icons`}</inlineCode>{`. Props som er støttet er `}<inlineCode parentName="p">{`color`}</inlineCode>{` og `}<inlineCode parentName="p">{`size`}</inlineCode>{`.
Det er også lagt inn støtte for `}<inlineCode parentName="p">{`width`}</inlineCode>{` og \`height for å sette riktig størrelse på ikoner som ikke er kvadratiske (eks. Ruter-ikonet).`}</p>
    <p>{`Width og height vil bli prioritert før size om du sender inn begge.`}</p>
    <p>{`Eksempel:`}</p>
    <pre><code parentName="pre" {...{}}>{`import { SeatIcon, RuterIcon } from '@entur/icons'

<View>
    <SeatIcon color="181c56" size={ 20 } />
    <RuterIcon color="ffffff" width={ 60 } height={16} />
</View>
`}</code></pre>
    <h3 {...{
      "id": "retningslinjer"
    }}>{`Retningslinjer`}</h3>
    <h4 {...{
      "id": "når-skal-ikoner-brukes"
    }}>{`Når skal ikoner brukes?`}</h4>
    <p>{`Ikoner skal brukes for å skape bedre navigasjon og brukeropplevelse. De skal oppleves som relevant for de formålene de brukes til, og for å hjelpe brukeren å forstå innholdet raskere enn med kun tekst. Ikoner benyttes oftest sammen med forklarende tekst, og helst ikke frittstående.`}</p>
    <p>{`Ikoner er `}<em parentName="p">{`ikke`}</em>{` ment som dekorasjon, og skal derfor `}<em parentName="p">{`kun`}</em>{` brukes der de gir verdi. Ikoner skal ikke være for detaljerte eller blandes i farger, det vil gjøre de mer til en illustrasjon istedenfor et ikon. Ikoner som ikke er en del av ikonbiblioteket skal ikke brukes. Utvikling av nye ikoner skal skje gjennom designansvarlig i Entur.`}</p>
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="success" src={colorDoSrc} alt="" mdxType="DoDontCard">
    Bruk samme farge på ikon og tekst når de skal stå sammen
  </DoDontCard>
  <DoDontCard variant="negative" src={colorDontSrc} alt="" mdxType="DoDontCard">
    Ikke bruk forskjellige farger på ikon og tekst
  </DoDontCard>
    </DoDontGroup>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="success" src={alignDoSrc} alt="" mdxType="DoDontCard">
    Når man bruker ikon ved siden av tekst, skal ikonet midtstilles
  </DoDontCard>
  <DoDontCard variant="negative" src={alignDontSrc} alt="" mdxType="DoDontCard">
    Ikke baseline-juster ikonet til teksten
  </DoDontCard>
    </DoDontGroup>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="success" src={sizeDoSrc} alt="" mdxType="DoDontCard">
    Størrelsen på både ikon og tekst skal alltid være lik og innenfor
    størrelse-skalaen
  </DoDontCard>
  <DoDontCard variant="negative" src={sizeDontSrc} alt="" mdxType="DoDontCard">
    Ikke bruk forskjellig størrelser på ikon og tekst
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      